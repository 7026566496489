import UserNavbar from '../components/Navbar/UserNavbar';
import useGetNonTypeSafe from '../hooks/useGetNonTypeSafe';
import { FundAccountsGet, UserDetails } from 'types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Banner from '../components/Banner';
import usePut from '../hooks/usePut';
import FormModal from '../components/FormModal';

const fallbackValues = {
   id: 0,
   email: '',
   name: '',
   unsubscribed: false,
};

interface ProfileData extends UserDetails {
   oldPassword?: string;
   newPassword?: string;
   confirmPassword?: string;
   unsubscribed?: boolean;
}

function Profile() {
   const [profile, profileError] = useGetNonTypeSafe<UserDetails>('me');
   const [fundsAndAccounts, fundsAndAccountsError] =
      useGetNonTypeSafe<FundAccountsGet[]>('me/accounts');

   const [formValues, setFormValues] = useState<ProfileData>(fallbackValues);

   const [sendData, putValue, putErrorMessage] = usePut<{}>('me');

   const [showPasswordModal, setShowPasswordModal] = useState(false);

   const [passwordUpdated, setPasswordUpdated] = useState(false);

   useEffect(() => {
      if (profile) {
         setFormValues(profile);
      }
   }, [profile]);

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setFormValues({
         ...formValues,
         [name]: value,
      });
   };

   const discardChanges = () => {
      setFormValues(profile ?? fallbackValues);
   };

   const handleUnsubscribe = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      setFormValues({
         ...formValues,
         unsubscribed: checked,
      });

      sendData({ unsubscribed: checked });
   };

   const saveChanges = async () => {
      const { oldPassword, newPassword, confirmPassword, ...data } = formValues;
      sendData(data);
   };

   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />
         {passwordUpdated && (
            <Banner type="success">Password successfully changed</Banner>
         )}
         {putValue && (
            <Banner type="success">Profile successfully updated</Banner>
         )}
         {putErrorMessage && (
            <Banner type="error"> Oops, something went wrong! </Banner>
         )}

         <FormModal
            title="Change Password"
            display={showPasswordModal}
            onClose={() => setShowPasswordModal(false)}
         >
            <ChangePassword
               closeModal={() => setShowPasswordModal(false)}
               setPasswordUpdated={setPasswordUpdated}
            />
         </FormModal>

         <div className="mx-4 py-20 md:py-24">
            <div className="max-w-3xl mx-auto">
               <div className="pb-1 md:pb-5 sm:flex md:items-center md:justify-between">
                  <h1 className="text-3xl font-bold leading-tight text-gray-900 text-left">
                     Profile
                  </h1>
                  <div className="mt-3 flex">
                     <button
                        onClick={discardChanges}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                     >
                        Discard Changes
                     </button>
                     <button
                        onClick={saveChanges}
                        type="button"
                        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                     >
                        Save Changes
                     </button>
                  </div>
               </div>
               <div className="mt-3 flex sm:mt-0 sm:ml-4"></div>
               <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                     {/* START Profile Form */}
                     <div className="mt-0">
                        <div className="md:grid md:grid-cols-2 md:gap-6">
                           <div className="mt-0 md:mt-0 md:col-span-2">
                              <form action="#" method="POST">
                                 <div>
                                    <div className="w-96 max-w-full">
                                       <label
                                          htmlFor="first_name"
                                          className="mb-2 block text-sm font-medium text-gray-700 text-left"
                                       >
                                          Full Name
                                       </label>
                                       <input
                                          onChange={handleChange}
                                          value={formValues?.name || ''}
                                          type="text"
                                          name="name"
                                          id="first_name"
                                          autoComplete="given-name"
                                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                       />
                                    </div>
                                    <div className="w-96 max-w-full mt-6">
                                       <label
                                          htmlFor="email_address"
                                          className="mb-2 block text-sm font-medium text-gray-700 text-left"
                                       >
                                          Email address
                                       </label>
                                       <input
                                          disabled
                                          onChange={handleChange}
                                          value={formValues?.email || ''}
                                          type="text"
                                          name="email"
                                          id="email_address"
                                          autoComplete="email"
                                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                       />
                                    </div>

                                    <div className="mt-6 flex">
                                       <button
                                          onClick={() =>
                                             setShowPasswordModal(true)
                                          }
                                          type="button"
                                          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                       >
                                          Change Password
                                       </button>
                                    </div>

                                    <div className="border-b border-gray-200 my-5"></div>

                                    <h3 className="text-lg font-medium text-left text-gray-700">
                                       Subscription Settings
                                    </h3>

                                    <div className="max-w-full mt-6">
                                       <input
                                          onChange={handleUnsubscribe}
                                          checked={formValues?.unsubscribed}
                                          type="checkbox"
                                          name="unsubscribed"
                                          id="unsubscribed"
                                          className="mr-2"
                                       />
                                       <label
                                          htmlFor="unsubscribed"
                                          className="text-sm font-medium text-gray-700 text-left inline-flex"
                                       >
                                          Opt-out of New Document Emails
                                       </label>
                                       {formValues?.unsubscribed ? (
                                          <p className="text-red-500 font-bold mt-3 mb-4">
                                             You will not receive an email when
                                             a new document is published
                                          </p>
                                       ) : (
                                          <p className="mt-3 mb-4 text-gray-500">
                                             If checked, you will not receive an
                                             email when a new document is
                                             published
                                          </p>
                                       )}
                                    </div>

                                    <div className="border-b border-gray-200 my-5"></div>

                                    {/* <div className="col-span-6 sm:col-span-4 mt-8">
                                       <label
                                          htmlFor="email_address"
                                          className="mb-2 block text-sm font-medium text-gray-700"
                                       >
                                          Multi-Factor Authentication
                                       </label>
                                       <button
                                          type="button"
                                          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                       >
                                          Change MFA Type
                                       </button>
                                    </div> */}
                                    <div className="col-span-6 sm:col-span-6 mt-8">
                                       <h3 className="text-lg font-medium text-left text-gray-700">
                                          Fund Accounts
                                       </h3>

                                       {fundsAndAccounts ? (
                                          <ul className="divide-y divide-gray-200">
                                             {fundsAndAccounts.map(
                                                fundAndAccount => (
                                                   <FundAndAccounts
                                                      key={fundAndAccount.id}
                                                      {...fundAndAccount}
                                                   />
                                                ),
                                             )}
                                          </ul>
                                       ) : (
                                          <div className="text-gray-500 text-sm my-4">
                                             Loading...
                                          </div>
                                       )}
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                     {/* END Profile Form */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

interface Props {
   closeModal: () => void;
   setPasswordUpdated: Dispatch<SetStateAction<boolean>>;
}
interface PasswordData {
   oldPassword: string;
   newPassword: string;
   confirmPassword: string;
}

function ChangePassword({ closeModal, setPasswordUpdated }: Props) {
   const [sendData] = usePut('me');

   const [formValues, setFormValues] = useState<PasswordData>({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
   });

   const [passwordError, setPasswordError] = useState('');

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setPasswordError('');

      setFormValues({
         ...formValues,
         [name]: value,
      });
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setPasswordError('');

      const { oldPassword, newPassword, confirmPassword } = formValues;

      if (!oldPassword || !newPassword || !confirmPassword) {
         return;
      } else if (newPassword && newPassword !== confirmPassword) {
         setPasswordError('Passwords do not match!');
         return;
      }

      try {
         await sendData(formValues);
         closeModal();
         setPasswordUpdated(true);
      } catch (err) {
         setPasswordError(
            'Can not update your password. Double check your password spelling.',
         );
      }
   };

   return (
      <>
         {passwordError && <Banner type="error"> {passwordError} </Banner>}
         <form onSubmit={handleSubmit}>
            <div className="w-96 max-w-full mt-6">
               <label
                  htmlFor="oldPassword"
                  className="mb-2 block text-sm font-medium text-gray-700 text-left"
               >
                  Old Password
               </label>
               <input
                  required
                  onChange={handleChange}
                  value={formValues?.oldPassword || ''}
                  type="password"
                  autoComplete="current-password"
                  name="oldPassword"
                  id="oldPassword"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               />
            </div>

            <div className="w-96 max-w-full mt-6">
               <label
                  htmlFor="newPassword"
                  className="mb-2 block text-sm font-medium text-gray-700 text-left"
               >
                  New Password
               </label>
               <input
                  required
                  onChange={handleChange}
                  value={formValues?.newPassword || ''}
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  autoComplete="new-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               />
            </div>

            <div className="w-96 max-w-full mt-6">
               <label
                  htmlFor="confirmPassword"
                  className="mb-2 block text-sm font-medium text-gray-700 text-left"
               >
                  Confirm New Password
               </label>
               <input
                  required
                  onChange={handleChange}
                  value={formValues?.confirmPassword || ''}
                  type="password"
                  name="confirmPassword"
                  autoComplete="new-password"
                  id="confirmPassword"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               />
            </div>
            <div className="mt-6 flex">
               <input
                  type="submit"
                  value="Update"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
               />
            </div>
         </form>
      </>
   );
}

function FundAndAccounts({ name, logo, accounts }: FundAccountsGet) {
   return (
      <li className="py-4 flex">
         {logo ? (
            <img className="h-14 w-14 rounded-md" src={logo} alt="fund-logo" />
         ) : (
            <span className="w-14"></span>
         )}

         <div className="ml-4 mt-2">
            <p className="text-sm font-medium text-gray-900 text-left">
               {name}
            </p>
            {accounts.map(account => (
               <p className="text-sm text-gray-500 text-left" key={account.id}>
                  {account.name}
               </p>
            ))}
         </div>
      </li>
   );
}

export default Profile;
