import React, { createContext, useEffect, useState } from 'react';
import { AccessToken, AdminToken, UserDetails, UserToken } from 'types';
import type { AdminOrUser } from 'types/interface';

import { get, getAuthToken } from '../lib/api';

type ContextProps = {
   user: AdminOrUser | null;
   handleUserLogin: () => Promise<void>;
   handleUserLogout: () => void;
};

export const UserContext = createContext<ContextProps>({
   user: null,
   handleUserLogin: async () => {},
   handleUserLogout: () => {},
});

export function isAdminToken(token: AccessToken | null): token is AdminToken {
   return token !== null && 'adminId' in token;
}

export function isSuperAdmin(token: AccessToken | null): token is AdminToken {
   return isAdminToken(token) && token.accessLevel === 'super-admin';
}

export function isUserToken(token: AccessToken | null): token is UserToken {
   return token !== null && !('adminId' in token);
}

export function isFundManager(token: AccessToken | null): token is UserToken {
   return !isAdminToken(token) && Boolean(token?.managedFunds?.length);
}

const UserContextProvider: React.FC<
   React.PropsWithChildren<React.PropsWithChildren<{}>>
> = ({ children }) => {
   const [user, setUser] = useState<AdminOrUser | null>(null);
   const urlParams = new URLSearchParams(location?.search);
   const impersonationId =
      urlParams.get('impersonationId') ??
      sessionStorage.getItem('impersonationId');

   const handleUserLogin = async () => {
      const res = await get('me');
      setUser(res);
   };

   const handleUserLogout = () => {
      setUser(null);
   };

   useEffect(() => {
      if (impersonationId && !sessionStorage.getItem('impersonationId')) {
         sessionStorage.setItem('impersonationId', impersonationId);
      }
   }, [impersonationId]);

   const authToken = getAuthToken();
   const pendingUserData = authToken && !user;
   useEffect(() => {
      if (pendingUserData) {
         handleUserLogin();
      }
   }, [authToken]);

   return (
      <UserContext.Provider
         value={{
            user,
            handleUserLogin,
            handleUserLogout,
         }}
      >
         {!pendingUserData && children}
      </UserContext.Provider>
   );
};

export default UserContextProvider;
