import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import {
   Account,
   ManagedFunds,
   SubDocRequestPost,
   SubDocRequestPostBulk,
   SubDocRequestPostSingle,
} from 'types';
import Banner from '../../../components/Banner';
import FormModal from '../../../components/FormModal';
import Loader from '../../../components/Loader';
import { UserContext, isFundManager } from '../../../contexts/UserContext';
const Survey = lazy(() => import('../../../components/SurveyForm/Survey'));
import { api } from '../../../lib/api';
import AccountRequest from './AccountRequest';
import BulkInvestorRequest from './BulkInvestorRequest';
import SingleInvestorRequest from './SingleInvestorRequest';
import SubDocApiModal from './SubDocApiModal';

interface RequestFormProps {
   onSingleSubmit: (
      fundId: number,
      data: SubDocRequestPostSingle,
      openFormInNewTab?: boolean,
   ) => void;
   onBulkSubmit: (fundId: number, data: SubDocRequestPostBulk) => void;
   errorMessage: string;
   loading: boolean;
   funds: ManagedFunds[] | undefined;
   baseFundUrl: string;
}

type RequestType = 'single' | 'bulk' | 'account' | 'fund-manager';

export default function RequestForm({
   onSingleSubmit,
   onBulkSubmit,
   errorMessage,
   loading,
   funds,
   baseFundUrl,
}: RequestFormProps) {
   const [selectedFund, setSelectedFund] = useState<number | undefined>();

   const [selectedForm, setSelectedForm] = useState<number | undefined>(0);
   const [requestType, setRequestType] = useState<RequestType>('single');

   const [accountIds, setAccountIds] = useState<number[]>([]);

   const [accounts, setAccounts] = useState<Account[]>([]);

   const { user } = useContext(UserContext);
   const [viewApiModal, setViewApiModal] = useState(false);

   const [formValues, setFormValues] = useState({
      userEmail: '',
      investorName: '',
      emailNotification: false,
      requiresSecondSignature: false,
      params: null,
   });

   const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
   ) => {
      setFormValues({
         ...formValues,
         [e.target.name]: e.target.value,
      });
   };

   const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
         ...formValues,
         [e.target.name]: e.target.checked,
      });
   };

   useEffect(() => {
      if (funds?.length) {
         setSelectedFund(funds[0].id);
      }
   }, [funds]);

   const forms = funds?.find(f => f.id === selectedFund)?.forms || [];

   useEffect(() => {
      if (selectedFund && forms.length) {
         setSelectedForm(forms[0].id);
         //get accounts for selected fund
         api.get<Account[]>(`${baseFundUrl}/${selectedFund}/accounts`).then(
            res => setAccounts(res),
         );
      } else {
         setSelectedForm(undefined);
      }
   }, [selectedFund]);

   const submitRequest = (params: any = null) => {
      if (!funds?.length) {
         return;
      }
      if (!selectedFund || !selectedForm) {
         alert('Please select a fund and a form');
         return;
      }

      const { userEmail, ...data } = formValues;

      if (requestType === 'single' || requestType === 'fund-manager') {
         onSingleSubmit(
            selectedFund,
            {
               ...data,
               userEmail,
               params,
               formId: selectedForm,
            },
            requestType === 'fund-manager',
         );
      } else {
         const userEmails = splitEmailsByDelimiters(userEmail);
         onBulkSubmit(selectedFund, {
            ...data,
            userEmails,
            accountIds,
            params,
            formId: selectedForm,
         });
      }
   };

   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submitRequest();
   };

   const handleSurveySubmit = async (survey: any) => {
      submitRequest(survey.data);
   };

   const splitEmailsByDelimiters = (emails: string) => {
      const delimiters = [' ', ';', '\n', '\r', '\t', ','];
      const splitEmails = emails
         .split(new RegExp(`(${delimiters.join('|')})`, 'g'))
         .filter(email => email && !delimiters.includes(email));
      return splitEmails;
   };

   const handleRequestTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setRequestType(name as RequestType);

      //clear values
      setAccountIds([]);

      const userEmail =
         name === 'fund-manager' ? `${isFundManager(user) && user.email}` : '';

      //if type is fund-manager require second signature. Otherwise deafult to no.
      setFormValues({
         ...formValues,
         userEmail,
         requiresSecondSignature: name === 'fund-manager',
      });
   };

   const handleParamsChange = (model: any) => {
      setFormValues({
         ...formValues,
         params: model.data,
      });
   };

   const paramsSurveyCode = forms.find(f => f.id === selectedForm)
      ?.activeVersion?.paramsSurveyCode;

   return (
      <form onSubmit={handleSubmit} className="text-left">
         {errorMessage && <Banner type="error">{errorMessage}</Banner>}
         {viewApiModal && selectedForm && selectedFund && (
            <FormModal
               title="Create a Form Request by API"
               onClose={() => setViewApiModal(false)}
               display={viewApiModal}
            >
               <SubDocApiModal
                  formId={selectedForm}
                  fundId={selectedFund}
                  userEmail={formValues.userEmail}
                  investorName={formValues.investorName}
                  emailNotification={formValues.emailNotification}
                  params={formValues.params}
                  requiresSecondSignature={formValues.requiresSecondSignature}
               />
            </FormModal>
         )}
         <div className="mb-4">
            <label className="block">
               <input
                  type="radio"
                  name="single"
                  checked={requestType === 'single'}
                  onChange={handleRequestTypeChange}
                  className="mr-2"
               />
               Single Investor Request
            </label>
            <label className="block">
               <input
                  type="radio"
                  name="bulk"
                  checked={requestType === 'bulk'}
                  onChange={handleRequestTypeChange}
                  className="mr-2"
               />
               Generate Bulk Investor Request
            </label>
            <label className="block">
               <input
                  type="radio"
                  name="account"
                  checked={requestType === 'account'}
                  onChange={handleRequestTypeChange}
                  className="mr-2"
               />
               Account Investor Request
            </label>
            <label className="block">
               <input
                  type="radio"
                  name="fund-manager"
                  checked={requestType === 'fund-manager'}
                  onChange={handleRequestTypeChange}
                  className="mr-2"
               />
               Subdocs filled out by Fund Manager for Investor
            </label>
         </div>

         <label
            htmlFor="fund"
            className="mb-2 block text-sm font-medium text-gray-700 text-left"
         >
            Funds
         </label>
         <select
            value={selectedFund}
            onChange={e => setSelectedFund(Number(e.target.value))}
            id="fund"
            name="fund"
            className="mt-4 pointer-events-auto font-x
                block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
         >
            {funds?.map(fund => (
               <option key={fund.id} value={fund.id}>
                  {fund.name}
               </option>
            ))}
         </select>

         <label
            htmlFor="forms"
            className="my-2 block text-sm font-medium text-gray-700 text-left"
         >
            Forms
         </label>
         <select
            value={selectedForm}
            onChange={e => setSelectedForm(Number(e.target.value))}
            id="forms"
            name="forms"
            className="mt-4 pointer-events-auto font-x
                block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
         >
            {forms?.map(form => (
               <option key={form.id} value={form.id}>
                  {form.name}
               </option>
            ))}
         </select>

         {requestType === 'bulk' && (
            <BulkInvestorRequest
               onChange={handleChange}
               emailAddress={formValues.userEmail}
            />
         )}

         {requestType === 'single' && (
            <SingleInvestorRequest
               onCheckboxChange={handleCheckboxChange}
               emailNotification={formValues.emailNotification}
               onChange={handleChange}
               email={formValues.userEmail}
               investorName={formValues.investorName}
            />
         )}

         {requestType === 'account' && (
            <AccountRequest
               selectedAccountIds={accountIds}
               accounts={accounts}
               onChange={setAccountIds}
            />
         )}

         {requestType !== 'fund-manager' && (
            <label className="block mt-2">
               <input
                  type="checkbox"
                  className="mr-2"
                  name="requiresSecondSignature"
                  checked={formValues.requiresSecondSignature}
                  onChange={handleCheckboxChange}
               />
               Send form to additional user after initial completion
            </label>
         )}

         {paramsSurveyCode && Object.keys(paramsSurveyCode).length > 0 ? (
            <>
               <Suspense
                  fallback={
                     <div className="justify-center text-center">
                        Loading...
                     </div>
                  }
               >
                  <div className="my-2 mt-4 block text-sm font-medium text-gray-700 text-left">
                     Configure Form
                  </div>
                  <Survey
                     showCompletedPage={false}
                     theme="modern"
                     showTitle={false}
                     showProgressBar="off"
                     json={paramsSurveyCode}
                     onValueChanged={handleParamsChange}
                     onComplete={handleSurveySubmit}
                  />
               </Suspense>
            </>
         ) : (
            <>
               {loading ? (
                  <div className="mt-4">
                     <Loader />
                  </div>
               ) : (
                  <button className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                     Submit
                  </button>
               )}
            </>
         )}
         {isFundManager(user) && (
            <div className="mt-4">
               <button
                  type="button"
                  className="link"
                  onClick={() => setViewApiModal(true)}
               >
                  See how to automate this
               </button>
            </div>
         )}
      </form>
   );
}
