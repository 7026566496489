import { useMsal } from '@azure/msal-react';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import {
   GoogleLoginResponse,
   GoogleLoginResponseOffline,
   useGoogleLogin,
} from 'react-google-login';

import { UserContext } from '../contexts/UserContext';
import { userAzureLogin, userGoogleLogin } from '../lib/api';

import { ReactComponent as MSLogo } from '../../src/svg/icon-ms.svg';
import { ReactComponent as GoogleLogo } from '../../src/svg/icon-google.svg';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import type { LocationDescriptor } from 'history';

const GOOGLE_CLIENT_ID =
   '181213760163-bl6gscf9dla4ba8tih5pto8q0ihrsoga.apps.googleusercontent.com';

interface Props {
   onError: (err: string) => void;
   redirectPath?: LocationDescriptor;
}

export default function ThirdPartyLogin({
   onError: setError,
   redirectPath = '/',
}: Props) {
   const { instance, inProgress } = useMsal();
   const { user, handleUserLogin } = useContext(UserContext);

   const history = useHistory();

   useEffect(() => {
      if (user) {
         history.push(redirectPath);
      }
   }, [user]);

   const microsoftLogin = async () => {
      let response: AuthenticationResult | null = null;
      if (inProgress !== InteractionStatus.None)
         response = await instance.handleRedirectPromise();
      if (!response)
         response = await instance.loginPopup({
            scopes: [],
            prompt: 'select_account',
         });

      if (response) {
         instance.setActiveAccount(response.account);
         userAzureLogin(response.idToken)
            .then(handleUserLogin)
            .catch(err => setError('Email not associated with an account'));
      } else {
         setError('Email not associated with an account');
      }
   };

   const googleLogin = async (
      res: GoogleLoginResponse | GoogleLoginResponseOffline,
   ) => {
      const { tokenId } = res as GoogleLoginResponse;

      try {
         const res = await userGoogleLogin(tokenId);
         handleUserLogin();
      } catch (err) {
         console.error(err);
         setError('Could not log into google');
      }
   };

   const googleLoginError = (error: any) => {
      console.error(error);
      /* possible TODO: don't display microsoft login? display login temporarily unavailble?*/
   };

   const { signIn } = useGoogleLogin({
      onSuccess: googleLogin,
      clientId: GOOGLE_CLIENT_ID,
      cookiePolicy: 'single_host_origin',
      onFailure: googleLoginError,
   });

   return (
      <div>
         <p className="text-sm text-gray-500 border-t border-gray-300 pt-2">
            If your Microsoft account uses the same email address as your user,
            you can log in using the button below.
         </p>

         <div className="mt-6 grid grid-cols-1 gap-3">
            {/* <div>
               <button
                  onClick={signIn}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
               >
                  <span className="sr-only">Sign in with Google</span>
                  <GoogleLogo className="mx-auto h-8 w-auto" />
               </button>
            </div> */}

            <div>
               <button
                  onClick={microsoftLogin}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
               >
                  <span className="sr-only">Sign in with Microsoft</span>
                  <MSLogo className="mx-auto h-8 w-auto" />
               </button>
            </div>
         </div>
      </div>
   );
}
