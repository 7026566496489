import { useContext, useEffect, useState } from 'react';
import LoggedOutLayout from '../components/LoggedOutLayout';
import { userLogin } from '../lib/api';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import type { LocationDescriptor } from 'history';
import ThirdPartyLogin from '../components/ThirdPartyLogin';
import { RedirectLocationState } from '../routes/ProtectedRoute';

function Login() {
   const { user, handleUserLogin } = useContext(UserContext);

   const history = useHistory();
   const location = useLocation<RedirectLocationState>();

   const urlParams = new URLSearchParams(location.search);
   const displayEmail = urlParams.get('u') || '';

   const [formValues, setFormValues] = useState({
      password: '',
      email: displayEmail,
   });
   const [error, setError] = useState('');

   const redirect: LocationDescriptor = location.state?.from || '/';

   useEffect(() => {
      if (user) {
         history.replace(redirect);
      }
   }, [user]);

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
         ...formValues,
         [e.target.name]: e.target.value,
      });
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
         const res = await userLogin(formValues);
         handleUserLogin();
      } catch (err) {
         setError(`The email and password you entered did not match our records.
         Please double-check and try again.`);
      }
   };

   return (
      <LoggedOutLayout>
         {/* <!-- Centered White Card Container -- Content Starts Here--> */}

         <h2 className="mb-6 text-left text-3xl font-extrabold text-gray-900">
            Sign in to your account
         </h2>

         {error ? (
            <div className="bg-red-200 p-4 rounded-md text-left my-2">
               {error}
            </div>
         ) : null}

         <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
               <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
               >
                  Email address
               </label>
               <div className="mt-1">
                  <input
                     value={formValues.email}
                     autoFocus
                     onChange={handleChange}
                     id="email"
                     name="email"
                     type="email"
                     autoComplete="email"
                     required
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </div>
            </div>

            <div>
               <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
               >
                  Password
               </label>
               <div className="mt-1">
                  <input
                     value={formValues.password}
                     onChange={handleChange}
                     id="password"
                     name="password"
                     type="password"
                     autoComplete="current-password"
                     required
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </div>
            </div>

            <div>
               <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
               >
                  Sign in
               </button>
            </div>
         </form>

         <Link
            className="my-4 block text-sm text-blue-400"
            to="/forgot-password"
         >
            Forgot Password
         </Link>

         <ThirdPartyLogin onError={setError} redirectPath={redirect} />
         {/* <!-- Centered White Card Container -- Content Ends Here--> */}
      </LoggedOutLayout>
   );
}

export default Login;
